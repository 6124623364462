







































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { CustomerOrderCustomerProperties } from './CustomerOrderCustomerView.vue';
import EventManager from "@/utilities/EventManager";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CustomerOrderService from "@/services/CustomerOrderService";

class CustomerOrderCreateDialogProperties {
    visible: boolean = false;
    events = new EventManager();
}

export { CustomerOrderCreateDialogProperties };

@Component({
    data: () => ({
        customer: {},
        loading: false,
        saving: false,
    }),
})
export default class CustomerOrderCreateDialogView extends Vue {
    @Prop({ default: new CustomerOrderCreateDialogProperties() })
    private properties: CustomerOrderCreateDialogProperties;
    @Prop() private customerOrderCustomer: CustomerOrderCustomerProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private customerOrderService = new CustomerOrderService();

    public get title() {
        return this.$t('title.customer-order') + ' - ' + this.$t('title.create');
    }

    public created() {
        this.customerOrderCustomer.events.subscribe('selected', this.selectedCustomer);
    }

    public destroyed() {
        this.customerOrderCustomer.events.remove('selected', this.selectedCustomer);        
    }

    public async submit() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            const customerOrder = {
                customerId: this.$data.customer?.id,
                status: "Draft",
				totalQuantity: 0,
				totalAmount: 0,
				totalWeight: 0
            };
            const r = await this.customerOrderService.post(customerOrder);

            await this.properties.events.fire("created", r.data);
            this.properties.visible = false;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public cancel() {
        if (this.$data.saving) {
            return;
        }
        this.properties.visible = false;
    }

    public selectCustomer() {
        this.customerOrderCustomer.tag = "create";
        this.customerOrderCustomer.visible = true;
    }

    public selectedCustomer(customer: any) {
        const tag = this.customerOrderCustomer.tag;
        if (tag === "create") {
			const facebookAccount = customer?.facebookAccount;
			const pageScopedId = facebookAccount?.pageScopedId ?? "";
			const tmp = pageScopedId !== "" ? `(${pageScopedId})` : "";

            customer.text = `${customer.code} - ${customer.name} ${tmp}`.trim();
            this.$data.customer = customer;
            this.customerOrderCustomer.visible = false;
        }
    }

    public customerCleared() {
        this.$data.customer = {};
    }
}
