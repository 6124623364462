





















































































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from '@/components/shared/AlertDialogView.vue';
import CustomerService from '@/services/CustomerService';
import ExceptionUtil from '@/utilities/ExceptionUtil';
import EventManager from '@/utilities/EventManager';
import StringUtil from "@/utilities/StringUtil";

class CustomerOrderCustomerProperties {
    visible: boolean = false;
    events = new EventManager();
    tag: any = null;
}

export { CustomerOrderCustomerProperties };

@Component({
    data: () => ({
        table: {
            recordCounts: [10, 20, 30, 40, 50],
            loading: false,
            pageNo: 1,
            pageCount: 1,
            recordCount: 20,
        },
        filter: {
            code: "",
            name: "",
			pageScopedId: ""
        },
        customers: []
    })
})
export default class CustomerOrderCustomerView extends Vue {
    @Prop({ default: new CustomerOrderCustomerProperties() })
    private properties: CustomerOrderCustomerProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private customerService = new CustomerService();
    private queueHandle: number = 0;    

    public get headers() {
        return [
            {
                text: this.$t('text.code'),
                value: "code",
                width: "20%"
            },
            {
                text: this.$t('text.name'),
                value: "formatted.name"
            },
			{
				text: this.$t('text.psid'),
				value: "facebookAccount.pageScopedId",
				width: "20%"
			},
            {
                value: "action",
                fixed: true,
                align: "center",
                width: "80px",
                sortable: false,
            },
        ];
    }

    public created() {
        this.load();
    }

    public loadQueue(delay: number = 500) {
        if (this.queueHandle !== 0) {
            clearTimeout(this.queueHandle);
        }

        this.queueHandle = setTimeout(() => {
            this.queueHandle = 0;
            this.load();
        }, delay);
    }

    public async load() {
        const pageNo = this.$data.table.pageNo;
        const rowCount = this.$data.table.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.table.loading = true;

        try {
            const filter = this.$data.filter;
            const r = await this.customerService.get({
                rowStart: rowStart,
                rowCount: rowCount,
                countRecord: true,
				code: StringUtil.enclose(filter.code, "%", "%"),
				pageScopedId: StringUtil.enclose(filter.pageScopedId, "%", "%"),
                hidden: false,
				search: filter.name,
				loadFacebookAccount: true
            });

            var count = Math.ceil(r.data.count / rowCount);
            if (count < 1) count = 1;

            const customers: any[] = r.data.customers;

			customers.forEach(e => {
				const f = e.facebookAccount;
				const u = f.uniqueNo ?? null;

				e.formatted = {
					name: u !== null ? `${e.name} [${u}]` : e.name
				};
			});

            this.$data.customers = customers.sort((lhs, rhs) => rhs.id - lhs.id);

            await Vue.nextTick();
            this.$data.table.pageCount = count;
            if (pageNo > count) {
                this.$data.table.pageNo = count;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.table.loading = false;
        }
    }

    public async selected(item: any) {
        await this.properties.events.fire('selected', item);
    }

    public close() {
        this.properties.visible = false;
    }
}
